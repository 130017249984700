<template>
  <div class="project-refund-details container fluid py-2">
    <div class="d-flex align-items-center">
      <fd-button class="circle justify-content-center" @click="goBack"
        ><i class="fas fa-chevron-left"></i
      ></fd-button>
      <h3 class="m-2 my-3">Refund Details</h3>
    </div>
    <!-- ============================== Refund ============================== -->
    <div v-if="dataLoaded" class="bordered-card p-3 mb-2">
      <div class="row justify-content-end">
        <refund-status :status="refund.status"></refund-status>
      </div>
      <div v-if="isHistory" class="my-2">
        <!-- Accepted/Approved Refund -->
        <div v-if="refund.status == STATUS.APPROVED" class="row">
          <download-button
            class="col-12 mb-1"
            label="Refund Receipt"
            :downloadItem="refund.refundReceipt"
            :filenamePrefix="`project_booking_refund_${refund.id}`"
            @download="downloadFile"
          >
          </download-button>
          <display-data
            class="col-12 sm-col-6 px-1"
            label="Refunded Amount"
            :content="`RM ${refund.refundDeposit}`"
          ></display-data>
          <display-data
            class="col-12 sm-col-6 px-1"
            label="Remark"
            :content="refund.refundApprovelRemark"
          ></display-data>
        </div>
        <!-- Rejected Refund -->
        <div v-if="refund.status == STATUS.REJECTED" class="row">
          <display-data class="col-12" label="Rejected Reason">
            <p class="reject-title mt-1">{{ rejectReason.title }}</p>
            <p>{{ rejectReason.message }}</p>
          </display-data>
        </div>
        <div class="col-12" style="border-bottom: solid 1px #ddd"></div>
      </div>

      <!-- ====================== Booking Refund Info ====================== -->
      <div v-if="dataLoaded" class="row">
        <display-data
          :class="displayCSS"
          label="Cancelled Phase"
          :content="refund.projectPurchase.currentPhase"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Booking Date"
          :content="$moment(refund.bookingSubmitDate).format('DD-MM-YYYY')"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Refund Submit Date"
          :content="$moment(refund.createdAt).format('DD-MM-YYYY')"
        ></display-data>
      </div>
      <div class="row">
        <display-data
          :class="displayCSS"
          label="Bank Name"
          :content="refund.bank"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Bank Account Number"
          :content="refund.bankAccountNumber"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Bank Account Name"
          :content="refund.beneficiaryName"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Remark"
          :content="refund.requestRemark"
        ></display-data>
      </div>
      <!-- Actions -->
      <div v-if="!isHistory" class="row justify-content-end mt-2">
        <fd-button class="bordered danger medium" @click="openRejectModal"
          >Reject</fd-button
        >
        <fd-button class="main medium ml-1" @click="openApproveModal"
          >Approve</fd-button
        >
      </div>
    </div>
    <!-- ============================== BUYER ============================== -->
    <div class="bordered-card p-3 mb-2">
      <h4 class="mb-4">Buyer Info</h4>

      <div class="row">
        <display-data
          :class="displayCSS"
          label="Name"
          :content="reservation.purchaserName1"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Contact Number"
          :content="reservation.phoneContact"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Email"
          :content="reservation.email"
        ></display-data>
      </div>
    </div>
    <!-- ============================== AGENT ============================== -->
    <agent-info
      v-if="refund.projectPurchase.agent"
      class="bordered-card p-3 mb-3"
      :agencyUser="refund.projectPurchase.agent"
    ></agent-info>
    <!-- ============================== UNIT ============================== -->
    <div class="bordered-card p-3 mb-2">
      <h4 class="mb-4">Unit Info</h4>
      <div v-if="dataLoaded" class="row">
        <display-data
          :class="displayCSS"
          label="Project Name"
          :content="refund.projectPurchase.project.name"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Property Category"
          :content="refund.projectPurchase.project.propertyCategory"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Property Type"
          :content="reservation.propertyType"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Unit Type"
          :content="refund.projectPurchase.projectUnitType.name"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Phase"
          :content="reservation.projectPhase"
        ></display-data>
        <display-data
          :class="displayCSS"
          label="Plot Number"
          :content="reservation.unitPlot"
        ></display-data>
      </div>
    </div>
    <!-- ========================= MODALS: APPROVE ========================= -->
    <modal v-model="approveModal.isShown">
      <approve-refund
        :refundId="refundId"
        @cancel="approveModal.isShown = false"
        @submit="approveRefund"
      ></approve-refund>
    </modal>
    <!-- ========================= MODALS: REJECT ========================== -->
    <modal v-model="rejectModal.isShown">
      <reject-refund
        :refundId="refundId"
        @cancel="rejectModal.isShown = false"
        @submit="rejectRefund"
      ></reject-refund>
    </modal>
  </div>
</template>

<script>
import { getFileExtension } from "@/utils/string";
import { projectRefundStatus as STATUS } from "@/enums";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    DownloadButton: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/DownloadButton"
      ),
    RefundStatus: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/RefundStatus"
      ),
    // Modals
    ApproveRefund: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/ApproveRefund"
      ),
    RejectRefund: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/RejectRefund"
      )
  },
  mixins: [],
  props: {
    isHistory: {
      type: Boolean,
      default: false
    },
    refundId: {
      type: [Number, String],
      required: true
    },
    getRefundDetailAPI: {
      type: Function,
      required: true
    },
    getRejectReasonAPI: {
      type: Function,
      required: true
    },
    approveRefundAPI: {
      type: Function,
      required: true
    },
    rejectRefundAPI: {
      type: Function,
      required: true
    },
    downloadFileAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      refund: {},
      reservation: {},
      rejectReason: {},

      dataLoaded: false,
      STATUS,
      displayCSS: "col-12 sm-col-6 md-col-4 lg-col-3 px-1",

      approveModal: {
        isShown: false
      },
      rejectModal: {
        isShown: false
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getRefundDetail();

        if (this.refund.status == STATUS.REJECTED) await this.getRejectReason();

        this.dataLoaded = true;

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    goBack() {
      let routeName = this.isHistory
        ? "ProjectRefundHistory"
        : "ManageProjectBookingRefunds";
      this.$router.replace({ name: routeName });
    },
    async openApproveModal() {
      this.approveModal.isShown = true;
    },
    async openRejectModal() {
      this.rejectModal.isShown = true;
    },
    // ========================== API Related =========================
    async getRefundDetail() {
      try {
        let data = await this.getRefundDetailAPI(this.refundId);

        this.refund = this._.cloneDeep(data);
        this.reservation = this._.cloneDeep(
          data.projectPurchase.projectPurchaseReservation
        );
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get refunds list. Please try again later."
        });
        throw error;
      }
    },
    async getRejectReason() {
      try {
        let data = await this.getRejectReasonAPI(this.refundId);

        this.rejectReason = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get reject refund reason. Please try again later."
        });
        throw error;
      }
    },
    async downloadFile(file, label, loading) {
      loading(true);
      let url = this.$getFileURL(file);
      let ext = getFileExtension(file.fileName);

      await this.downloadFileAPI(url, label, ext);
      loading(false);
    },
    async approveRefund(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveRefundAPI(id, payload);
        this.approveModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "The refund has been approved and completed successfully."
        });
        this.$store.commit("setIsLoading", false);

        this.goBack();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve refund. Please try again later."
        });
      }
    },
    async rejectRefund(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.rejectRefundAPI(id, payload);
        this.rejectModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "The refund has been rejected successfully."
        });
        this.$store.commit("setIsLoading", false);

        this.goBack();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to reject refund. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.project-refund-details {
  .reject-title {
    font-size: 16px;
  }
}
</style>
